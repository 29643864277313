import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import {
  ArrowBackIos,
  CreditCard,
  InfoOutlined,
  TrendingDownOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Tooltip as MuiTooltip } from "@mui/material";
import { BackgroundCircle } from "../common/BackgroundCircle";
// import { ReactComponent as EndOfMonth } from "../../assets/svg/end_of_month.svg";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../store";
import { InitialState, setIsAuthorized } from "../../store/slices/authSlice";
import useAccountDetails from "../../hooks/useAccountDetails";
import { useEffect, useState } from "react";
import NarmiAPI from "../../store/modules/NarmiAPI";
import MXAPI from "../../store/modules/MXAPI";
import React from "react";
import { IPageProps, IDailyTransaction, TransactionType, DailyTransactionType } from "../../models/common";
import { calculateTotalIncomeExpense, calculateDailyTransactions } from "../../utils/calculations";
import { formatMoney, calculatePercentageChange } from "../../utils/common";

interface CustomBarShapeProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  stroke: string;
  strokeDasharray?: string;
}

const CustomBarShape: React.FC<CustomBarShapeProps> = ({
  x,
  y,
  width,
  height,
  fill,
  stroke,
  strokeDasharray,
}) => {
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeDasharray ? 2 : 0}
      strokeDasharray={strokeDasharray}
    />
  );
};

const MAGNOLIA_URL = process.env.REACT_APP_MAGNOLIA_URL   || "https://magnolia-liafi.uat.narmitech.com";
const ENV = process.env.REACT_APP_ENV || "development";

export const CashFlowForecast: React.FC<IPageProps> = ({ setSelectedPage }) => {
  const dispatch = useDispatch();
  const interestRate = useSelector(
    (state: TRootState) => (state.auth as InitialState).interestRate
  );
  const isLoadingAccountDetails = useSelector(
    (state: TRootState) => (state.auth as InitialState).isLoadingAccountDetails
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState<
    "1month" | "2months" | "3months"
  >("1month");
  const [income, setIncome] = useState("0.00");
  const [incomePercentage, setIncomePercentage] = useState(0);
  const [expenses, setExpenses] = useState("0.00");
  const [expensesPercentage, setExpensesPercentage] = useState(0);
  const [getNarmiUserAccounts] = NarmiAPI().useLazyGetUserAccountsQuery();
  const [getNarmiUserTransactions] =
    NarmiAPI().useLazyGetUserTransactionsQuery();
  const [getMXUserAccounts] = MXAPI().useLazyGetMXUserAccountsQuery();
  const [getMXUserTransactions] =
    MXAPI().useLazyGetMXUserTransactionsByAccountQuery();
  const accountDetails = useAccountDetails();
  const [cashFlowData, setCashFlowData] = useState<IDailyTransaction[]>([]);
  const [enoughChartData, setEnoughChartData] = useState(true);

  useEffect(() => {
    const getIncomeAndExpenses = async () => {
      try {
        setIsLoading(true);

        const narmiAccountData = await getNarmiUserAccounts().unwrap();

        const mxAccountData = await getMXUserAccounts().unwrap();

        let checkMicrList: string[] = [];

        const externalNarmiAccounts = narmiAccountData.accounts.filter(
          (account) => account.source === "external"
        );

        if (ENV === "production") {
          const checkMicrListProd = externalNarmiAccounts.map(
            (account) => account.check_micr.slice(-4) 
          );
          checkMicrList = checkMicrListProd;
        } else{
          const checkMicrListDev = externalNarmiAccounts.map(
            (account) => account.check_micr
          );
          checkMicrList = checkMicrListDev;
        }
        const matchingMxAccounts = mxAccountData.accounts.filter(
          (mxAccount) => checkMicrList.includes(mxAccount.account_number)
        );

        let date = new Date();
        let prevousDate = new Date();
        let range = 0;
        switch (selectedRange) {
          case "1month":
            date.setDate(date.getDate() - 30);
            prevousDate.setDate(prevousDate.getDate() - 60);
            range = 30;
            break;
          case "2months":
            date.setDate(date.getDate() - 60);
            prevousDate.setDate(prevousDate.getDate() - 120);
            range = 60;
            break;
          case "3months":
            date.setDate(date.getDate() - 90);
            prevousDate.setDate(prevousDate.getDate() - 180);
            range = 90;
            break;
          default:
            break;
        }

        const fromDate = date.toISOString().split("T")[0];
        const frompreviousDate = prevousDate.toISOString().split("T")[0];
        const toDate = new Date().toISOString().split("T")[0];

        const narmiTransactionsData = await getNarmiUserTransactions({
          settled_from: fromDate,
          settled_to: toDate,
          records_per_page: 1000,
        }).unwrap();

        const narmiPreviousTransactionsData = await getNarmiUserTransactions({
          settled_from: fromDate,
          settled_to: toDate,
          records_per_page: 1000,
        }).unwrap();

        const mxTransactionsData = await Promise.all(
          matchingMxAccounts.map(async (account) => {
            const transactions = await getMXUserTransactions({
              id: account.guid,
              from_date: fromDate,
              to_date: toDate,
              records_per_page: 1000,
            }).unwrap();
            return transactions.transactions;
          })
        );

        const mxPreviousTransactionsData = await Promise.all(
          matchingMxAccounts.map(async (account) => {
            const transactions = await getMXUserTransactions({
              id: account.guid,
              from_date: frompreviousDate,
              to_date: fromDate,
              records_per_page: 1000,
            }).unwrap();
            return transactions.transactions;
          })
        );

        const mxTransactionsDataAll = mxTransactionsData.reduce(
          (acc, curr) => acc.concat(curr),
          []
        );

        const mxPreviousTransactionsDataAll = mxPreviousTransactionsData.reduce(
          (acc, curr) => acc.concat(curr),
          []
        );

        //Calculates the total income and expenses
        const totalIncome = calculateTotalIncomeExpense(
          narmiTransactionsData.transactions,
          mxTransactionsDataAll,
          TransactionType.Credit
        );
        setIncome(formatMoney(totalIncome));

        const totalExpenses = calculateTotalIncomeExpense(
          narmiTransactionsData.transactions,
          mxTransactionsDataAll,
          TransactionType.Debit
        );
        setExpenses(formatMoney(totalExpenses));

        const previousTotalIncome = calculateTotalIncomeExpense(
          narmiPreviousTransactionsData.transactions,
          mxPreviousTransactionsDataAll,
          TransactionType.Credit
        );
        setIncomePercentage(
          calculatePercentageChange(totalIncome, previousTotalIncome)
        );

        const previousTotalExpenses = calculateTotalIncomeExpense(
          narmiPreviousTransactionsData.transactions,
          mxPreviousTransactionsDataAll,
          TransactionType.Debit
        );
        setExpensesPercentage(
          calculatePercentageChange(totalExpenses, previousTotalExpenses)
        );

        //Calculate Cashflow Forecast Engine
        const dailyTransactions = calculateDailyTransactions([
          ...narmiTransactionsData.transactions,
          ...mxTransactionsDataAll,
        ], range);
        if (dailyTransactions.length < range) {
          setEnoughChartData(false);
        } else {
          const forecast = generateForecast(dailyTransactions, range);
          setCashFlowData([
            ...convertOutflowsAndDays(dailyTransactions),
            ...forecast,
          ]);
          setEnoughChartData(true);
        }
      } catch (error) {
        console.error("Something went wrong");
        dispatch(setIsAuthorized(false));
      } finally {
        setIsLoading(false);
      }
    };

  

    function generateForecast(
      historicalData: IDailyTransaction[],
      period: number
    ): IDailyTransaction[] {
      const totalDays = historicalData.length;
      const totalInflow = historicalData.reduce(
        (sum, day) => sum + day.inflow,
        0
      );
      const totalOutflow = historicalData.reduce(
        (sum, day) => sum + day.outflow,
        0
      );

      const avgInflow = totalInflow / totalDays;
      const avgOutflow = totalOutflow / totalDays;

      // Calculate standard deviation for variability
      const inflowVariance =
        historicalData.reduce(
          (sum, day) => sum + Math.pow(day.inflow - avgInflow, 2),
          0
        ) / totalDays;
      const outflowVariance =
        historicalData.reduce(
          (sum, day) => sum + Math.pow(day.outflow - avgOutflow, 2),
          0
        ) / totalDays;

      const inflowStdDev = Math.sqrt(inflowVariance);
      const outflowStdDev = Math.sqrt(outflowVariance);

      const forecastData: IDailyTransaction[] = [];

      for (let i = 0; i < period; i++) {
        const inflow = avgInflow + (Math.random() - 0.5) * 2 * inflowStdDev; // Adding variability
        const outflow = avgOutflow + (Math.random() - 0.5) * 2 * outflowStdDev; // Adding variability

        forecastData.push({
          date: (i + historicalData.length + 1).toString(),
          inflow: Math.abs(parseFloat(inflow.toFixed(2))),
          outflow: -Math.abs(parseFloat(outflow.toFixed(2))),
          type: DailyTransactionType.Future,
        });
      }

      return forecastData;
    }
    function convertOutflowsAndDays(
      transactions: IDailyTransaction[]
    ): IDailyTransaction[] {
      return transactions.map((transaction, index) => ({
        date: `${index + 1}`,
        inflow: Math.abs(parseFloat(transaction.inflow.toFixed(2))),
        outflow: -Math.abs(parseFloat(transaction.outflow.toFixed(2))),
        type: DailyTransactionType.Past,
      }));
    }

    getIncomeAndExpenses();
  }, [getNarmiUserAccounts, getMXUserAccounts, dispatch, getNarmiUserTransactions, getMXUserTransactions, selectedRange, incomePercentage, expensesPercentage]);

  return (
    <>
      <Box
        sx={{
          minHeight: "25vh",
        }}
        marginBottom="50px"
      >
        <Box
          zIndex={-1}
          position="absolute"
          right="0px"
          sx={{ minHeight: "20vh" }}
          display={{ xs: "none", sm: "flex" }}
          justifyContent="flex-end"
          alignItems="center"
          overflow="hidden"
        >
          <BackgroundCircle size={800}>
            <BackgroundCircle size={550}>
              <BackgroundCircle size={300}></BackgroundCircle>
            </BackgroundCircle>
          </BackgroundCircle>
        </Box>
        <Box marginTop={{ xs: 8, sm: 0 }}>
          <Grid
            container
            spacing={10}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={3}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    textAlign: "center",
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "27px",
                    letterSpacing: "-0.48px",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  Tor Score
                  <MuiTooltip
                    title={
                      <>
                        Tor Score is a cutting-edge tool designed to
                        revolutionize the way you monitor your business
                        finances. Want to learn more?{" "}
                        <button
                          onClick={() => { window.top ? window.top.location.href ="https://liafi.co/tor-score/" : window.location.href ="https://liafi.co/tor-score/"}}
                          style={{
                            background: "none",
                            border: "none",
                            padding: "0",
                            margin: "0",
                            color: "inherit",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Click here
                        </button>
                      </>
                    }
                    arrow
                  >
                    <InfoOutlined sx={{ color: "#192653" }} />
                  </MuiTooltip>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontFamily: "Work Sans",
                    fontSize: "70px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "82.977px",
                    letterSpacing: "-1.66px",
                  }}
                >
                  {isLoadingAccountDetails ? (
                    <CircularProgress style={{ marginTop: "10px" }} />
                  ) : (
                    <>
                      <Box
                        component="span"
                        sx={{
                          color: "#1881FA",
                        }}
                      >
                        {accountDetails.cashflowScore < 0
                          ? 0
                          : accountDetails.cashflowScore}
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          color: "#101828",
                          opacity: "0.4",
                        }}
                      >
                        /100
                      </Box>
                    </>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    flexWrap: { xs: "nowrap", sm: "wrap" },
                    overflowX: "scroll",
                    paddingX: { xs: 3, sm: 0 },
                    paddingBottom: 1,
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="20px 20px">
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Cash on Hand
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "36px", sm: "28px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "56px",
                            letterSpacing: "-0.96px",
                          }}
                        >
                          {isLoadingAccountDetails ? (
                            <CircularProgress style={{ marginTop: "10px" }} />
                          ) : (
                            <>
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                $
                              </Box>
                              {accountDetails.cashOnHand}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="20px 20px">
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Forecasted Expenses
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "36px", sm: "28px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "56px",
                            letterSpacing: "-0.96px",
                          }}
                        >
                          {isLoadingAccountDetails ? (
                            <CircularProgress style={{ marginTop: "10px" }} />
                          ) : (
                            <>
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                $
                              </Box>
                              {accountDetails.forecastedExpenses}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="20px 20px">
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Cash on Deposit in Liafi
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "36px", sm: "28px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "56px",
                            letterSpacing: "-0.96px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {isLoadingAccountDetails ? (
                            <CircularProgress style={{ marginTop: "10px" }} />
                          ) : (
                            <>
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                $
                              </Box>
                              {accountDetails.cashOnDeposit}
                            </>
                          )}
                          <Box
                            component={"span"}
                            sx={{
                              color: "#36C685",
                              marginLeft: "8px",
                              fontSize: "20px",
                            }}
                          >
                            {interestRate ? interestRate.toFixed(2) : "0"}%
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="20px 20px">
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Cash Reserve
                        </Box>
                        {isLoadingAccountDetails ? (
                          <CircularProgress style={{ marginTop: "10px" }} />
                        ) : (
                          <>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#192653",
                                leadingTrim: "both",
                                textEdge: "cap",
                                fontFamily: "Work Sans",
                                fontSize: { xs: "36px", sm: "28px" },
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "56px",
                                letterSpacing: "-0.96px",
                              }}
                            >
                              {accountDetails.cashReserve}
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                months
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "40px 40px 0px 0px",
          backgroundColor: "#FFFFFF",
        }}
        padding={{ xs: "20px", sm: "50px" }}
      >
        <Box
          sx={{
            color: "#192653",
            fontFamily: "Work Sans",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "27px",
            letterSpacing: "-0.4px",
            paddingBottom: { xs: 0, sm: 3 },
            cursor: "pointer",
          }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          onClick={() => setSelectedPage("Dashboard")}
        >
          <ArrowBackIos fontSize="small" sx={{ color: "#192653" }} />
          Go Back
        </Box>
        <Grid
          container
          padding={{ xs: "20px 0px", sm: "40px 0px" }}
          spacing={{ xs: 4, sm: 0 }}
        >
          <Grid item xs={12} sm={12}>
            <Card
              sx={{
                marginLeft: { xs: 0, sm: 1 },
                marginRight: { xs: 0, sm: 3 },
                paddingY: 5,
                borderRadius: "20px",
              }}
            >
              <Box padding={{ xs: "20px 30px", sm: "40px 40px" }}>
                <Stack
                  justifyContent={"space-between"}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 0 }}
                >
                  <Box
                    sx={{
                      color: "#192653",
                      fontFamily: "Mulish",
                      fontSize: "32px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "40px",
                      letterSpacing: "-0.64px",
                    }}
                  >
                    Cash Flow Forecast
                  </Box>
                  <Box
                    display={{ xs: "block", sm: "none" }}
                    paddingY={3}
                    sx={{
                      color: "#6D7282",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "-0.16px",
                    }}
                  >
                    Cashflow Forecast uses algorithms and your historical
                    transactional data to predict finances, aiding smart
                    business decisions for stability and growth.
                  </Box>
                  <ButtonGroup variant="outlined">
                    <Button
                      sx={{
                        borderColor: "#DDDEE5",
                        bgcolor: selectedRange === "1month" ? "#E8F2FF" : "",
                        color: "#192653",
                        fontSize: { xs: "16px", sm: "24px" },
                        textTransform: "none",
                        borderRadius: "8px",
                      }}
                      onClick={() => setSelectedRange("1month")}
                    >
                      30 days
                    </Button>
                    <Button
                      sx={{
                        borderColor: "#DDDEE5",
                        bgcolor: selectedRange === "2months" ? "#E8F2FF" : "",
                        color: "#192653",
                        fontSize: { xs: "16px", sm: "24px" },
                        textTransform: "none",
                      }}
                      onClick={() => setSelectedRange("2months")}
                    >
                      60 days
                    </Button>
                    <Button
                      sx={{
                        borderColor: "#DDDEE5",
                        bgcolor: selectedRange === "3months" ? "#E8F2FF" : "",
                        color: "#192653",
                        fontSize: { xs: "16px", sm: "24px" },
                        textTransform: "none",
                        borderRadius: "8px",
                      }}
                      onClick={() => setSelectedRange("3months")}
                    >
                      90 days
                    </Button>
                  </ButtonGroup>
                </Stack>
                <Box
                  display={{ xs: "none", sm: "block" }}
                  paddingY={3}
                  sx={{
                    color: "#6D7282",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "-0.16px",
                  }}
                >
                  Cashflow Forecast uses algorithms and your historical
                  transactional data to predict finances, aiding smart business
                  decisions for stability and growth.
                </Box>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 2, sm: 3 }}
                  marginTop={{ xs: 4, sm: 0 }}
                >
                  <Stack spacing={0.4}>
                    <Stack direction="row" spacing={1}>
                      <Box
                        sx={{
                          color: "#141E42",
                          fontFamily: "Work Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.16px",
                        }}
                      >
                        Income
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        leadingTrim: "both",
                        textEdge: "cap",
                        fontFamily: "Work Sans",
                        fontSize: "48px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "56px",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress style={{ marginTop: "10px" }} />
                      ) : (
                        <>
                          <Box
                            component={"span"}
                            sx={{
                              color: "#141E42",
                              opacity: 0.3,
                            }}
                          >
                            $
                          </Box>
                          <Box
                            component={"span"}
                            sx={{
                              color: "#192653",
                            }}
                          >
                            {income}
                          </Box>
                        </>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        color: incomePercentage > 0 ? "#2DA56F" : "#AD2727",
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "300",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      {isLoading ? (
                        <div></div>
                      ) : (
                        <>
                          <Box>{incomePercentage > 0 ? "+" : "-"}</Box>
                          <Box>
                            {incomePercentage > 0
                              ? Math.floor(incomePercentage)
                              : Math.abs(Math.floor(incomePercentage))}
                            %
                          </Box>
                          {incomePercentage > 0 ? (
                            <TrendingUpOutlined
                              sx={{ borderColor: "#2DA56F" }}
                            />
                          ) : (
                            <TrendingDownOutlined
                              sx={{ borderColor: "#AD2727" }}
                            />
                          )}
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      bgcolor: "#B7D8FD",
                      borderColor: "#B7D8FD",
                      width: 1.5,
                    }}
                  />
                  <Divider
                    sx={{
                      bgcolor: "#B7D8FD",
                      borderColor: "#B7D8FD",
                    }}
                  />
                  <Stack spacing={0.4}>
                    <Stack direction="row" spacing={1}>
                      <Box
                        sx={{
                          color: "#141E42",
                          fontFamily: "Work Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.16px",
                        }}
                      >
                        Expenses
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        leadingTrim: "both",
                        textEdge: "cap",
                        fontFamily: "Work Sans",
                        fontSize: "48px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "56px",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress style={{ marginTop: "10px" }} />
                      ) : (
                        <>
                          <Box
                            component={"span"}
                            sx={{
                              color: "#141E42",
                              opacity: 0.3,
                            }}
                          >
                            $
                          </Box>
                          <Box
                            component={"span"}
                            sx={{
                              color: "#192653",
                            }}
                          >
                            {expenses}
                          </Box>
                        </>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        color: expensesPercentage > 0 ? "#2DA56F" : "#AD2727",
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "300",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      {isLoading ? (
                        <div></div>
                      ) : (
                        <>
                          <Box>{expensesPercentage > 0 ? "+" : "-"}</Box>
                          <Box>
                            {expensesPercentage > 0
                              ? Math.floor(expensesPercentage)
                              : Math.abs(Math.floor(expensesPercentage))}
                            %
                          </Box>
                          {expensesPercentage > 0 ? (
                            <TrendingUpOutlined
                              sx={{ borderColor: "#2DA56F" }}
                            />
                          ) : (
                            <TrendingDownOutlined
                              sx={{ borderColor: "#AD2727" }}
                            />
                          )}
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      bgcolor: "#B7D8FD",
                      borderColor: "#B7D8FD",
                      width: 1.5,
                    }}
                  />
                  <Divider
                    sx={{
                      bgcolor: "#B7D8FD",
                      borderColor: "#B7D8FD",
                    }}
                  />
                  <Stack spacing={0.4}>
                    <Stack direction="row" spacing={1}>
                      <Box
                        sx={{
                          color: "#141E42",
                          fontFamily: "Work Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.16px",
                        }}
                        display="flex"
                        alignItems="center"
                      >
                        <CreditCard sx={{ marginRight: 1, color: "#B8BCCA" }} />
                        Accounts
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        leadingTrim: "both",
                        textEdge: "cap",
                        fontFamily: "Work Sans",
                        fontSize: "48px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "56px",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      {isLoadingAccountDetails ? (
                        <CircularProgress style={{ marginTop: "10px" }} />
                      ) : (
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                          }}
                        >
                          {accountDetails.accountsCount}
                        </Box>
                      )}
                    </Stack>
                    <Button
                      variant="text"
                      sx={{
                        color: "#1881FA",
                        textTransform: "none",
                        justifyContent: "flex-start",
                      }}
                      onClick={() => { window.top ? window.top.location.href = `${MAGNOLIA_URL}/settings/linked_accounts` : window.location.href = `${MAGNOLIA_URL}/settings/linked_accounts`}}
                      >
                      Add more accounts
                    </Button>
                  </Stack>
                </Stack>
              </Box>
              <Box
                width={{ xs: "100%", sm: "95%" }}
                height={{ xs: 250, sm: 550 }}
                display={{ xs: "none", sm: "block" }}
              >
                <ResponsiveContainer>
                  {enoughChartData ? (
                    <BarChart
                      data={cashFlowData}
                      barCategoryGap={1}
                      stackOffset="sign"
                    >
                      <XAxis dataKey="date" />
                      <YAxis axisLine={false} />
                      <CartesianGrid vertical={false} strokeDasharray="3 3" />
                      <Tooltip />
                      <Bar
                        dataKey="inflow"
                        fill="#1881FA"
                        stackId="stack"
                        shape={(props: any) => {
                          const { x, y, width, height, payload } = props;
                          return (
                            <CustomBarShape
                              x={x as number}
                              y={y as number}
                              width={width as number}
                              height={height as number}
                              fill={
                                payload.type === DailyTransactionType.Past
                                  ? "#1881FA"
                                  : "#E8F2FF"
                              }
                              stroke={
                                payload.type === DailyTransactionType.Future
                                  ? "#1881FA"
                                  : ""
                              }
                              strokeDasharray={
                                payload.type === DailyTransactionType.Future
                                  ? "4 2"
                                  : undefined
                              }
                            />
                          );
                        }}
                      />
                      <Bar
                        dataKey="outflow"
                        fill="#DDDEE5"
                        stackId="stack"
                        shape={(props: any) => {
                          const { x, y, width, height, payload } = props;
                          return (
                            <CustomBarShape
                              x={x as number}
                              y={y as number}
                              width={width as number}
                              height={height as number}
                              fill={
                                payload.type === DailyTransactionType.Past
                                  ? "#DDDEE5"
                                  : "#E8E9EE"
                              }
                              stroke={
                                payload.type === DailyTransactionType.Future
                                  ? "#000000"
                                  : ""
                              }
                              strokeDasharray={
                                payload.type === DailyTransactionType.Future
                                  ? "4 2"
                                  : undefined
                              }
                            />
                          );
                        }}
                      />
                    </BarChart>
                  ) : (
                    <Alert severity="error">
                      No enough data to display the chart.
                    </Alert>
                  )}
                </ResponsiveContainer>
              </Box>
            </Card>
          </Grid>
          {/* Commented out for MVP
           <Grid item xs={12} sm={4}>
            <Stack spacing={2}>
              <Card sx={{ borderRadius: "20px" }}>
                <Box padding="40px 40px">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <SvgIcon
                      component={EndOfMonth}
                      viewBox="-6 -6 48 48"
                      sx={{
                        bgcolor: "#E8F2FF",
                        borderRadius: "100px",
                        width: { xs: "60px", sm: "80px" },
                        height: { xs: "60px", sm: "80px" },
                      }}
                    />
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Mulish",
                        fontSize: { xs: "16px", sm: "24px" },
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "24px",
                        letterSpacing: "-0.36px",
                      }}
                    >
                      End of the month is crowded with expenses
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      color: "#192653",
                      fontFamily: "Work Sans",
                      fontSize: { xs: "18px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "24px",
                      letterSpacing: "-0.16px",
                    }}
                    padding={{ xs: "30px 0px", sm: "50px 0px" }}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    Cashflow Forecast uses algorithms and your historical
                    transactional data to predict finances, aiding smart
                    business decisions for stability and growth.
                  </Box>
                  <Stack direction="row" spacing={5} alignItems="center">
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Mulish",
                        textTransform: "none",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: "12px",
                        letterSpacing: "0.32px",
                        padding: { xs: "15px 20px", sm: "18px 24px" },
                        backgroundColor: "#1881FA",
                        fontWeight: "700",
                        ":hover": {
                          backgroundColor: "#1881FA",
                        },
                      }}
                    >
                      Start Now
                    </Button>
                    <Box
                      sx={{
                        color: "#2DA56F",
                        textAlign: "right",
                        fontFamily: "Work Sans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "-0.36px",
                      }}
                    >
                      Save $1,550
                    </Box>
                  </Stack>
                </Box>
              </Card>
              <Card sx={{ borderRadius: "20px" }}>
                <Box padding="40px 40px">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <SvgIcon
                      component={EndOfMonth}
                      viewBox="-6 -6 48 48"
                      sx={{
                        bgcolor: "#E8F2FF",
                        borderRadius: "100px",
                        width: { xs: "60px", sm: "80px" },
                        height: { xs: "60px", sm: "80px" },
                      }}
                    />
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Mulish",
                        fontSize: { xs: "16px", sm: "24px" },
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "24px",
                        letterSpacing: "-0.36px",
                      }}
                    >
                      End of the month is crowded with expenses
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      color: "#192653",
                      fontFamily: "Work Sans",
                      fontSize: { xs: "18px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "24px",
                      letterSpacing: "-0.16px",
                    }}
                    padding={{ xs: "30px 0px", sm: "50px 0px" }}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    Cashflow Forecast uses algorithms and your historical
                    transactional data to predict finances, aiding smart
                    business decisions for stability and growth.
                  </Box>
                  <Stack direction="row" spacing={5} alignItems="center">
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Mulish",
                        textTransform: "none",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: "12px",
                        letterSpacing: "0.32px",
                        padding: { xs: "15px 20px", sm: "18px 24px" },
                        backgroundColor: "#1881FA",
                        fontWeight: "700",
                        ":hover": {
                          backgroundColor: "#1881FA",
                        },
                      }}
                    >
                      Start Now
                    </Button>
                    <Box
                      sx={{
                        color: "#2DA56F",
                        textAlign: "right",
                        fontFamily: "Work Sans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "-0.36px",
                      }}
                    >
                      Save $1,550
                    </Box>
                  </Stack>
                </Box>
              </Card>
            </Stack>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
