import React from 'react';
import { Box, Button,Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IMXConnectedAccounts } from '../../hooks/useAccountDetails';

const MAGNOLIA_URL = process.env.REACT_APP_MAGNOLIA_URL   || "https://magnolia-liafi.uat.narmitech.com";

interface IncompletedTorScoreModalProps {
  open: boolean;
  handleClose: () => void;
  connectedAccounts: IMXConnectedAccounts | undefined;
}
const IncompletedTorScoreModal: React.FC<IncompletedTorScoreModalProps> = ({open, handleClose, connectedAccounts}) => {

  return (
    <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        width: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div></div>
        <CloseIcon
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <h3 style={{ marginBottom: "10px" }}>
        Your Tor Score might be inaccurate or incomplete
      </h3>
      <span
        style={{ marginBottom: "10px", display: "block", color: "#666" }}
      >
        Oops, we’ve detected a problem with your linked external accounts and are unable to accurately calculate your Tor Score. Please send us a message to report this problem.  We can get this fixed by removing your externally linked accounts and will reply to your message when you are able to re-link them. Keep in mind, resolution may take up to 3 business days and may temporarily impact your ability to transfer funds externally.
      </span>
      <span
        style={{ marginBottom: "10px", display: "block", color: "#666" }}
      >
        Status of your connected accounts:
      </span>
      {
        connectedAccounts?.accounts.map((account, index) => (
          <span key={index} style={{ marginBottom: "10px", display: "block", color: "#666" }}>
            Ending with {account.check_micr} - {account.connection_status === 'CONNECTED' ? <span style={{color: '#339900', fontWeight: 'bold'}}>CONNECTED</span> : <span style={{color: '#cc3300',  fontWeight: 'bold'}}>DISCONNECTED</span>}
          </span>
        ))
      }
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#1881FA",
          fontWeight: "700",
          ":hover": {
            backgroundColor: "#1881FA",
          },
        }}
        onClick={() => {
          window.top
            ? (window.top.location.href =
                `${MAGNOLIA_URL}/messages/`)
            : (window.location.href =
                `${MAGNOLIA_URL}/messages/`);
        }}
      >
        Send Message
      </Button>
    </Box>
  </Modal>
  );
};

export default IncompletedTorScoreModal;